<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="itemsWithIndex"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title tag class="primary--text">User List</v-toolbar-title>
          <v-dialog v-model="editDialog" max-width="500px" persistent>
            <v-card>
              <v-card-title>
                <span class="text-h5 primary--text">Edit User</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedUser.firstname"
                        label="First Name"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedUser.lastname"
                        label="Last Name"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedUser.email"
                        label="Email"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedUser.designation"
                        label="Designation"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="editedUser.role"
                        :items="roles"
                        label="Role(s)"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="12" class="pb-0 pt-0">
                      <!-- <v-checkbox
                        class="mr-4"
                        v-model="editedUser.dataValidationStatus"
                        label="Data Validation"
                        v-bind:false-value="0"
                        v-bind:true-value="1"
                      >
                      </v-checkbox> -->

                      <v-checkbox
                        class="mr-4"
                        v-model="editedUser.alert1"
                        label="Alert"
                        false-value="0"
                        true-value="1"
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="primary--text" text @click="close">
                  Cancel
                </v-btn>

                <v-btn class="primary--text" text @click="postEditUser">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
              <v-card-title class="error white--text">
                Delete User <v-icon style="color:white;margin-left:15px;">mdi-delete-empty</v-icon>
              </v-card-title>
              <v-card-text style="padding:20px 24px;font-weight:bold;">
                Are you sure you want to delete this user? ({{delUserName}})
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="success--text" text @click="close">Cancel</v-btn>
                <v-btn class="error--text" text @click="postDeleteUser">YES</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.no`]="props">
        {{ props.item.index }}
      </template>
      <template v-slot:[`item.actions`]="props">
        <template>
          <v-icon
            small
            class="mr-2 green--text"
            @click="editUserItem(props.item)"
            >mdi-pencil</v-icon
          >
          <v-icon
            small
            text
            class="red--text"
            @click="deleteUser(props.item.idUser,props.item.name)"
            >mdi-delete</v-icon
          >
        </template>
      </template>
    </v-data-table>
  </v-container>
</template>


<script>
import axios from "axios";

export default {
  data: () => ({
    editDialog: false,
    dialogDelete: false,
    delSelected: "",
    delUserName: "",
    headers: [
      { text: "No.", align: "start", value: "no" },
      { text: "Name", value: "name" },
      { text: "Designation", value: "designation" },
      { text: "Role(s)", value: "role" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    userData: [],
    roles: [
      { text: "admin" },
      { text: "operation" },
      { text: "qaqc" },
      { text: "general" }
    ],
    editedUser: {
      firstname: "",
      lastname: "",
      email: "",
      designation: "",
      phone: "",
      roles: "",
      alert1: "",
      dataValidationStatus: "",
    },
    editedIndex: -1,
  }),

  methods: {
    getAllUser() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/users/all`, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.userData = response.data;
          console.log(this.userData);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    postEditUser() {
      const payload = this.editedUser;
      axios
        .post(`${process.env.VUE_APP_API_URL}/users/edit`, payload, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.getAllUser();
          console.log(response.data);
          this.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    postDeleteUser() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/users/del?id=${this.delSelected}`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          let temp = this.userData.findIndex(
            (item) => item.idUser === this.delSelected
          );
          this.userData.splice(temp, 1);
          this.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editUserItem(item) {
      this.editedIndex = this.userData.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.editDialog = true;
    },

    deleteUser(val,user) {
      this.delSelected = val;
      this.delUserName = user;
      this.dialogDelete = true;
    },

    close() {
      this.editDialog = false;
      this.dialogDelete = false;
    },
  },

  computed: {
    itemsWithIndex() {
      return this.userData.map((userData, index) => ({
        ...userData,
        index: index + 1,
      }));
    },
  },

  mounted() {
    this.getAllUser();
  },
};
</script>

<style lang="scss" scoped>
</style>